import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { useNavigationLabel } from 'utils/navigation'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

import Layout from 'components/Layout'
import RoomFeatures from 'components/Rooms/RoomsFeatures'
import ContentHeading from 'components/Content/ContentHeading'
import Content from 'components/Content'

const IS_BROWSER = typeof window !== 'undefined'

const AppPage = ({ location, data: staticData, pageContext }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })
  const appPage = data.prismicApp.data

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const roomsIndexPage = data.prismicRoomsIndex.data

  return (
    <Layout
      heroImage={appPage.hero}
      crumbs={crumbs}
      crumbLabel={useNavigationLabel(pageContext.uid)}
      metaTitle={appPage.meta_title}
      metaDescription={appPage.meta_description}
      pageTitle={appPage.title}
      location={location}
    >
      <ContentHeading heading={appPage.section_1_heading} />
      <Content
        text={appPage.section_1_content.html}
        appleStoreUrl={data.prismicGlobals.data.apple_store_url}
        googleStoreUrl={data.prismicGlobals.data.google_play_url}
        alignLeft
      />
      {appPage.section_1_images && (
        <section className="my-6 md:my-10 lg:my-12 xl:my-18">
          <div className="container">
            <div className="flex flex-wrap -ml-2">
              {appPage.section_1_images.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full md:w-1/2 pl-2 mb-4 md:mb-0"
                  >
                    {item.image.localFile && (
                      <Img
                        fluid={item.image.localFile.childImageSharp.fluid}
                        alt={item.image.alt}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )}
      {appPage.section_2_hero.localFile && (
        <Img
          fluid={appPage.section_2_hero.localFile.childImageSharp.fluid}
          alt={appPage.section_2_hero.alt}
        />
      )}
      {/* {appPage.section_2_features && (
        <div className="mb-1 md:mb-8 lg:mb-12 xl:mb-18">
          <RoomFeatures
            heading={appPage.section_2_title}
            features={appPage.section_2_features}
            buttonPrimaryLink={appPage.section_2_button.uid}
            buttonPrimaryText={appPage.section_2_button_text}
          />
        </div>
      )} */}
      <Content
        text={appPage.section_3_rich_text.html}
        buttonLink={appPage.section_3_button}
        buttonText={appPage.section_3_button_text}
      />
      <div className="mb-1">
        <RoomFeatures features={roomsIndexPage.features} />
      </div>
    </Layout>
  )
}

AppPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageMiaQuery = graphql`
  query {
    prismicApp {
      data {
        title
        hero {
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        meta_description
        meta_title
        section_1_heading
        section_1_content {
          html
        }
        section_1_images {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        section_2_button {
          uid
          url
          link_type
        }
        section_2_button_text
        section_2_features {
          feature_name
          feature_description {
            html
          }
          feature_icon
        }
        section_2_title
        section_2_hero {
          alt
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        section_3_button {
          uid
          url
          link_type
        }
        section_3_button_text
        section_3_rich_text {
          html
        }
      }
    }
    prismicGlobals {
      data {
        apple_store_url
        google_play_url
      }
    }
    prismicRoomsIndex {
      data {
        features {
          feature_description {
            html
          }
          feature_name
          feature_icon
        }
      }
    }
  }
`

export default AppPage
