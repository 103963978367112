import React from 'react'
import GooglePlayBadge from '../../images/google-play-badge.png'
import AppStoreBadge from '../../images/app-store-badge.png'
import Link from '../Link'

const Content = ({
  heading,
  text,
  button,
  buttonText,
  buttonColour,
  appleStoreUrl,
  googleStoreUrl,
  alignLeft = false,
}) => {
  let buttonType = ''
  if (buttonColour === 'Green') {
    buttonType = 'button-secondary'
  } else {
    buttonType = 'button-primary'
  }
  return (
    <section className="mt-6 md:mt-8 lg:mt-10 xl:mt-12 mb-8 md:mb-10 lg:mb-12 xl:mb-14">
      <div className="container">
        <div className={`max-w-4xl ${alignLeft ? '' : 'mx-auto'}`}>
          {heading && (
            <div className="mb-6 md:mb-8 text-lg md:text-2xl font-semibold">
              <h3>{heading}</h3>
            </div>
          )}
          {text && (
            <div
              className="rte"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></div>
          )}
          {googleStoreUrl && (
            <a
              data-ga-target={'google-play-button'}
              href={googleStoreUrl}
              className="inline-block mt-6 md:mt-10"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="h-12 w-auto mr-4"
                src={GooglePlayBadge}
                alt="Get it on Google Play"
              />
            </a>
          )}
          {appleStoreUrl && (
            <a
              href={appleStoreUrl}
              data-ga-target={'app-store-button'}
              className="inline-block mt-6 md:mt-10"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="h-12 w-auto"
                src={AppStoreBadge}
                alt="Download on the App Store"
              />
            </a>
          )}
          {(button?.url || button?.uid) && (
            <Link
              link={button}
              text={buttonText}
              className={`w-full max-w-2xs mt-6 ${buttonType}`}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
